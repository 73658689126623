import { NODE_STATES } from '../reducer'

const hGraph = {
    title: 'h',
    nodes: [
      {
        id: 'h.1',
        label: 'H.1',
        color: NODE_STATES['enabled'].color,
        chosen: NODE_STATES['enabled'].chosen,
        title: 'Simulating Nature',
        textbookFilename: 'lessons/H.1/Lesson.md',
        isComplete: false,
        nextLessonId: 'h.2',
        nextChapterIds: null,
        challenges: [
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.1.1/challengeDescription.md',
            id: 'H.1.1',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.1.2/challengeDescription.md',
            id: 'H.1.2',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.1.3/challengeDescription.md',
            id: 'H.1.3',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
        ]
      },
      {
        id: 'h.2',
        label: 'H.2',
        color: NODE_STATES['enabled'].color,
        chosen: NODE_STATES['enabled'].chosen,
        font: NODE_STATES['enabled'].font,
        title: 'Unitaries',
        textbookFilename: 'lessons/H.2/Lesson.md',
        isComplete: false,
        nextLessonId: 'h.3',
        nextChapterIds: null,
        challenges: [
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.2.1/challengeDescription.md',
            id: 'H.2.1',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.2.2/challengeDescription.md',
            id: 'H.2.2',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
        ]
      },
      {
        id: 'h.3',
        label: 'H.3',
        color: NODE_STATES['enabled'].color,
        chosen: NODE_STATES['enabled'].chosen,
        font: NODE_STATES['enabled'].font,
        title: 'Hamiltonians',
        textbookFilename: 'lessons/H.3/Lesson.md',
        isComplete: false,
        nextLessonId: 'h.4',
        nextChapterIds: null,
        challenges: [
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.3.1a/challengeDescription.md',
            id: 'H.3.1a',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.3.1b/challengeDescription.md',
            id: 'H.3.1b',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.3.1c/challengeDescription.md',
            id: 'H.3.1c',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.3.1d/challengeDescription.md',
            id: 'H.3.1d',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.3.1e/challengeDescription.md',
            id: 'H.3.1e',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.3.1f/challengeDescription.md',
            id: 'H.3.1f',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
        ]
      },
      {
        id: 'h.4',
        label: 'H.4',
        color: NODE_STATES['enabled'].color,
        chosen: NODE_STATES['enabled'].chosen,
        font: NODE_STATES['enabled'].font,
        title: 'Energy in quantum systems',
        textbookFilename: 'lessons/H.4/Lesson.md',
        isComplete: false,
        nextLessonId: 'h.5',
        nextChapterIds: null,
        challenges: [
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.4.1/challengeDescription.md',
            id: 'H.4.1',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.4.2a/challengeDescription.md',
            id: 'H.4.2a',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.4.2b/challengeDescription.md',
            id: 'H.4.2b',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
        ]
      },
      {
        id: 'h.5',
        label: 'H.5',
        color: NODE_STATES['enabled'].color,
        chosen: NODE_STATES['enabled'].chosen,
        font: NODE_STATES['enabled'].font,
        title: 'Approximating exponentials',
        textbookFilename: 'lessons/H.5/Lesson.md',
        isComplete: false,
        nextLessonId: 'h.6',
        nextChapterIds: null,
        challenges: [
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.5.1/challengeDescription.md',
            id: 'H.5.1',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.5.2/challengeDescription.md',
            id: 'H.5.2',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.5.3/challengeDescription.md',
            id: 'H.5.3',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.5.4/challengeDescription.md',
            id: 'H.5.4',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
        ]
      },
      {
        id: 'h.6',
        label: 'H.6',
        color: NODE_STATES['enabled'].color,
        chosen: NODE_STATES['enabled'].chosen,
        font: NODE_STATES['enabled'].font,
        title: 'Linear combination of unitaries',
        textbookFilename: 'lessons/H.6/Lesson.md',
        isComplete: false,
        nextLessonId: 'h.7',
        nextChapterIds: null,
        challenges: [
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.6.1a/challengeDescription.md',
            id: 'H.6.1a',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.6.1b/challengeDescription.md',
            id: 'H.6.1b',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.6.2a/challengeDescription.md',
            id: 'H.6.2a',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.6.2b/challengeDescription.md',
            id: 'H.6.2b',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.6.3a/challengeDescription.md',
            id: 'H.6.3a',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.6.3b/challengeDescription.md',
            id: 'H.6.3b',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
          {
            isComplete: false,
            isCorrect: null,
            descriptionFilename: 'lessons/H.6.3c/challengeDescription.md',
            id: 'H.6.3c',
            answer: '',
            questionTemplate: '',
            plottingData: null,
            challengeMessage: ''
          },
        ]
      },
      {
        id: 'h.7',
        label: 'H.7',
        color: NODE_STATES['enabled'].color,
        chosen: NODE_STATES['enabled'].chosen,
        font: NODE_STATES['enabled'].font,
        title: 'Generalizing PREPARE and SELECT',
        textbookFilename: 'lessons/H.7/Lesson.md',
        isComplete: false,
        nextLessonId: null,
        nextChapterIds: null,
        challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/H.7.1/challengeDescription.md',
          id: 'H.7.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/H.7.2a/challengeDescription.md',
          id: 'H.7.2a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/H.7.2b/challengeDescription.md',
          id: 'H.7.2b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/H.7.2c/challengeDescription.md',
          id: 'H.7.2c',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/H.7.3/challengeDescription.md',
          id: 'H.7.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
       ]
      },
      {
        id: 'h.8',
        label: 'H.8',
        color: NODE_STATES['enabled'].color,
        chosen: NODE_STATES['enabled'].chosen,
        font: NODE_STATES['enabled'].font,
        title: 'Comparing methods',
        textbookFilename: 'lessons/H.8/Lesson.md',
        isComplete: false,
        nextLessonId: null,
        nextChapterIds: null,
        challenges: [
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.8.1a/challengeDescription.md',
          id: 'H.8.1a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.8.1b/challengeDescription.md',
          id: 'H.8.1b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.8.1c/challengeDescription.md',
          id: 'H.8.1c',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.8.1d/challengeDescription.md',
          id: 'H.8.1d',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.8.2a/challengeDescription.md',
          id: 'H.8.2a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.8.2b/challengeDescription.md',
          id: 'H.8.2b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.8.2c/challengeDescription.md',
          id: 'H.8.2c',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.8.2d/challengeDescription.md',
          id: 'H.8.2d',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.8.3/challengeDescription.md',
          id: 'H.8.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
       ]
      },
      {
        id: 'h.9',
        label: 'H.9',
        color: NODE_STATES['enabled'].color,
        chosen: NODE_STATES['enabled'].chosen,
        font: NODE_STATES['enabled'].font,
        title: 'Qubitization',
        textbookFilename: 'lessons/H.9/Lesson.md',
        isComplete: false,
        nextLessonId: null,
        nextChapterIds: null,
        challenges: [
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.9.1a/challengeDescription.md',
          id: 'H.9.1a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.9.1b/challengeDescription.md',
          id: 'H.9.1b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.9.2a/challengeDescription.md',
          id: 'H.9.2a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.9.2b/challengeDescription.md',
          id: 'H.9.2b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.9.2c/challengeDescription.md',
          id: 'H.9.2c',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.9.3a/challengeDescription.md',
          id: 'H.9.3a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.9.3b/challengeDescription.md',
          id: 'H.9.3b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          descriptionFilename: 'lessons/H.9.3c/challengeDescription.md',
          id: 'H.9.3c',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
       ],
      },
    ],
    edges: [
      { from: 'h.1', to: 'h.2' },
      { from: 'h.2', to: 'h.3' },
      { from: 'h.3', to: 'h.4' },
      { from: 'h.4', to: 'h.5' },
      { from: 'h.5', to: 'h.6' },
	    { from: 'h.6', to: 'h.7' },
      { from: 'h.7', to: 'h.8' },
      { from: 'h.8', to: 'h.9' },
    ]
}

export default hGraph
