import tutorial from './tutorial'
import chapters from './chapters'
import iGraph from './iGraph'
import aGraph from './aGraph'
import hGraph from './hGraph'
import gGraph from './gGraph'
import fGraph from './fGraph'
import pGraph from './pGraph'
import sGraph from './sGraph'
import qGraph from './qGraph'

const graphs = [
  tutorial,
  chapters,
  iGraph,
  aGraph,
  hGraph,
  gGraph,
  fGraph,
  pGraph,
  sGraph,
  qGraph, 
]

export default graphs
