import { useDispatch, useSelector } from 'react-redux'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import NodeTree from '../components/NodeTree'
import LessonChallenges from '../components/LessonChallenges'
import LessonTextBook from '../components/LessonTextBook'
import XanaduInformation from '../components/XanaduInformation'
import { updateCurrentLessonId } from '../store/reducer'

import './Lesson.css'

function Lesson() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const state = useSelector((state) => {
    return {
      showGraph: state.showGraph,
      showLessonChallenges: state.showLessonChallenges,
      showLessonTextbook: state.showLessonTextbook,
      graphs: state.graphs,
      currentLessonId: state.currentLessonId
    }
  });

  const currentLesson = useSelector((state) => {
    let currentLesson = null
    const currentChapter = state.graphs.find((lesson) => state.currentLessonId && (lesson.title === state.currentLessonId[0]))
    if (currentChapter) {
      currentLesson = currentChapter.nodes.find((node) => node.id === state.currentLessonId)
      currentLesson.isStarted = currentLesson.challenges.some(challenge => challenge.isComplete)
    }

    // currentLessonId is not a valid lesson
    if (state.currentLessonId && !currentLesson) {
      dispatch(updateCurrentLessonId(null))
      navigate('/')
    }

    return currentLesson
  })

  return (
    <div className="Lesson">
      <NodeTree
        showGraph={state.showGraph}
        graphs={state.graphs}
        lessonId={state.currentLessonId}
        currentLessonIsComplete={currentLesson ? currentLesson.isComplete : false}
        currentLessonIsStarted={currentLesson ? currentLesson.isStarted : false}>
      </NodeTree>
      {currentLesson &&
        <div className={`Lesson__content ${state.showLessonChallenges && state.showLessonTextbook ? '' : 'Lesson__content--no-gap'}`}>
          <LessonChallenges
            lessonId={state.currentLessonId}
            challenges={currentLesson.challenges}
            showGraph={state.showGraph}
            showLessonTextbook={state.showLessonTextbook}
            showLessonChallenges={state.showLessonChallenges}
            currentLessonIsComplete={currentLesson ? currentLesson.isComplete : false}
            currentLessonIsStarted={currentLesson ? currentLesson.isStarted : false}
            nextLessonId={currentLesson ? currentLesson.nextLessonId : null}
            nextChapterIds={currentLesson ? currentLesson.nextChapterIds : null}
            lessonTitle={currentLesson.title}>
          </LessonChallenges>
          <LessonTextBook
            showGraph={state.showGraph}
            showLessonChallenges={state.showLessonChallenges}
            showLessonTextbook={state.showLessonTextbook}
            textbookFilename={currentLesson.textbookFilename}
            lessonTitle={currentLesson.title}
            lessonId={currentLesson.id}>
          </LessonTextBook>
        </div>
      }
      {!currentLesson && <XanaduInformation />}
    </div>
  )
}

export default memo(Lesson)
