import PropTypes from 'prop-types'
import { memo } from 'react'

import Modal from './Modal'
import Markdown from './Markdown'

function XQCInfoModal(props) {
  return (
    <Modal
      showModal={props.showInformationModal}
      onClose={() => props.toggleShowInformationModal(false)}
      title="Xanadu Quantum Codebook"
      content={<Markdown filename="xanadu-information.md" />}
      footerContent={<button
        className="text-white font-bold py-2 px-4 rounded transition duration-200 bg-red-6 hover:bg-red-4"
        type="button"
        onClick={() => props.toggleShowInformationModal(false)}>
        Close
      </button>}>
    </Modal>
  )
}

XQCInfoModal.propTypes = {
  showInformationModal: PropTypes.bool.isRequired,
  toggleShowInformationModal: PropTypes.func.isRequired
}

export default memo(XQCInfoModal)
