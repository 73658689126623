import { useEffect, useState, useRef, memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import markdownIt from 'markdown-it'
import markdownItMathjax from 'markdown-it-mathjax'
import markdownItIterator from 'markdown-it-for-inline'
import { useSelector } from 'react-redux'

import hljs from 'highlight.js/lib/core'
import python from 'highlight.js/lib/languages/python'

import 'highlight.js/styles/base16/github.css'
import './Markdown.css'

hljs.registerLanguage('python', python)

function Markdown(props) {
    const markdownRef = useRef()
    const [text, getText] = useState('')
    const mathjax = useSelector((state) => state.mathjax)

    const markdown = markdownIt({
      html: true,
      linkify: true
    })
    .use(markdownItMathjax())
    .use(markdownItIterator, 'url_new_win', 'link_open', function (tokens, index) {
      const attrs = tokens[index].attrs.find(attr => attr[0] === 'href')
      const href = attrs[1]

      if (href && (!href.includes(window.location.origin) && !href.startsWith('/') && !href.startsWith('#'))) {
        tokens[index].attrPush(['target', '_blank'])
        tokens[index].attrPush(['rel', 'noopener noreferrer'])
      }
    })

    useMemo(() => {
      if (props.filename) {
        const markdownFile = import(`../markdown/${props.filename}`)
        markdownFile.then(res => {
          fetch(res.default)
            .then(res => res.text())
            .then(res => {
              const renderedMd = markdown.render(res)
              getText(renderedMd)
              hljs.highlightAll()
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filename])

    useEffect(() => {
      if (text && mathjax) {
        mathjax.Hub.Queue(
          function() {
            if (mathjax.InputJax.TeX.resetEquationNumbers) {
              mathjax.InputJax.TeX.resetEquationNumbers();
            }
          },
          ['Typeset', mathjax.Hub, markdownRef.current]
        );
      }
    }, [text, mathjax])

    return (
      <article
        className="Markdown"
        ref={markdownRef}
        dangerouslySetInnerHTML={{__html: text}}>
      </article>
    );
}

Markdown.propTypes = {
  filename: PropTypes.string
}

export default memo(Markdown)
