import { NODE_STATES } from '../reducer'

const gGraph = {
  title: 'g',
  nodes: [
    {
      id: 'g.1',
      label: 'G.1',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Introduction to Grover search',
      textbookFilename: 'lessons/G.1/Lesson.md',
      isComplete: false,
      nextLessonId: 'g.2',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.1.1/challengeDescription.md',
          id: 'G.1.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.1.2a/challengeDescription.md',
          id: 'G.1.2a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.1.2b/challengeDescription.md',
          id: 'G.1.2b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
      ]
    },
    {
      id: 'g.2',
      label: 'G.2',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Searching with geometry',
      textbookFilename: 'lessons/G.2/Lesson.md',
      isComplete: false,
      nextLessonId: 'g.3',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.2.1/challengeDescription.md',
          id: 'G.2.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.2.2/challengeDescription.md',
          id: 'G.2.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'g.3',
      label: 'G.3',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Searching with circuits',
      textbookFilename: 'lessons/G.3/Lesson.md',
      isComplete: false,
      nextLessonId: 'g.4',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.3.1/challengeDescription.md',
          id: 'G.3.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.3.2/challengeDescription.md',
          id: 'G.3.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.3.3/challengeDescription.md',
          id: 'G.3.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'g.4',
      label: 'G.4',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Steps and speedups',
      textbookFilename: 'lessons/G.4/Lesson.md',
      isComplete: false,
      nextLessonId: 'g.5',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.4.1a/challengeDescription.md',
          id: 'G.4.1a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.4.1b/challengeDescription.md',
          id: 'G.4.1b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.4.2/challengeDescription.md',
          id: 'G.4.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
      ]
    },
    {
      id: 'g.5',
      label: 'G.5',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Multiple solutions',
      textbookFilename: 'lessons/G.5/Lesson.md',
      isComplete: false,
      nextLessonId: null,
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.5.1/challengeDescription.md',
          id: 'G.5.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.5.2a/challengeDescription.md',
          id: 'G.5.2a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.5.2b/challengeDescription.md',
          id: 'G.5.2b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.5.3/challengeDescription.md',
          id: 'G.5.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/G.5.4/challengeDescription.md',
          id: 'G.5.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
      ]
    }
  ],
  edges: [
    { from: 'g.1', to: 'g.2' },
    { from: 'g.2', to: 'g.3' },
    { from: 'g.3', to: 'g.4' },
    { from: 'g.4', to: 'g.5' }
  ]
}

export default gGraph
