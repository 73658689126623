import { Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import './Modal.css'

function Modal(props) {
  return ReactDOM.createPortal(
    <Fragment>
      {props.showModal ? (
        <Fragment>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="Modal">
                  {props.showClose &&
                    <button
                      className="close-button"
                      onClick={(e) => props.onClose(e)}>
                      <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.3 5.71C17.91 5.32 17.28 5.32 16.89 5.71L12 10.59L7.11 5.7C6.72 5.31 6.09 5.31 5.7 5.7C5.31 6.09 5.31 6.72 5.7 7.11L10.59 12L5.7 16.89C5.31 17.28 5.31 17.91 5.7 18.3C6.09 18.69 6.72 18.69 7.11 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.11C18.68 6.73 18.68 6.09 18.3 5.71Z" fill="#23262C"/>
                      </svg>
                    </button>
                  }
                  <div className="flex items-start justify-between rounded-t relative mb-2">
                    <h2 className="my-auto h1">
                      {props.title}
                    </h2>
                  </div>
                  <div ref={props.contentRef} className="relative flex-auto overflow-auto m-h-96 my-auto">
                    {props.content}
                  </div>
                  <div className={`flex items-center justify-end ${props.footerContent ? 'mt-6' : ''}`}>
                    {props.footerContent &&
                      props.footerContent
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black">
            </div>
          </Fragment>
        ) : null}
    </Fragment>
    , document.getElementById('modal'))
}

Modal.defaultProps = {
  showClose: true,
  footerContent: null,
  contentRef: null,
}

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  footerContent: PropTypes.node,
  contentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
}

export default Modal
