import { createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import latestState from './state'

const migrations = {
  0: (state) => {
    return {
      ...state
    }
  },
  // Example:
  1: (state) => {
    // Changes in this migration:
    // * Updates showLessonTextbook to true
    return {
      ...state,
      showLessonTextbook: latestState.showLessonTextbook,
    }
  },
  2: (state) => {
    // Changes in this migration:
    // * Adds `userOutput` to Graphs' Nodes' challenges
    return {
      ...state,
      graphs: latestState.graphs
    }
  },
  3: (state) => {
    // Changes in this migration:
    // * Adds new chapters 'f', 'p', and 's'
    return {
      ...state,
      graphs: latestState.graphs.map((newGraph) => {
        const oldGraph = state.graphs.find(oldGraph => oldGraph.title === newGraph.title)

        return (oldGraph && oldGraph.title !== 'chapters') ? oldGraph : newGraph
      })
    }
  },
  4: (state) => {
    // Changes in this migration:
    // * Adds h8 and h9 nodes
    const latestHGraph = latestState.graphs.find(graph => graph.title === 'h');
    const h8Node = latestHGraph.nodes.find(node => node.id === 'h.8')
    const h9Node = latestHGraph.nodes.find(node => node.id === 'h.9')
    const h8Edge = latestHGraph.edges.find(edge => edge.from === 'h.7' && edge.to === 'h.8')
    const h9Edge = latestHGraph.edges.find(edge => edge.from === 'h.8' && edge.to === 'h.9')
    return {
      ...state,
      graphs: state.graphs.map((graph) => {
        if (graph.title === 'h') {
          graph.nodes = [
            ...graph.nodes,
            h8Node,
            h9Node
          ]
          graph.edges = [
            ...graph.edges,
            h8Edge,
            h9Edge
          ]
        }

        return graph
      }),
    };
  },
};

export const persistConfig = {
  key: 'xqc',
  version: 4,
  storage,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: process.env.NODE_ENV === 'development' })
}
