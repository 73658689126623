import { NODE_STATES } from '../reducer'

const qGraph = {
  title: 'q',
  nodes: [
    {
      id: 'q.1',
      label: 'Q.1',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      title: 'No exponential magic',
      textbookFilename: 'Q.1/Lesson.md',
      isComplete: false,
      nextLessonId: 'q.2',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.1.1/challengeDescription.md',
          id: 'Q.1.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.1.2/challengeDescription.md',
          id: 'Q.1.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        }
      ]
    },
    {
      id: 'q.2',
      label: 'Q.2',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'The magic 8-ball',
      textbookFilename: 'Q.2/Lesson.md',
      isComplete: false,
      nextLessonId: 'q.3',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.2.1/challengeDescription.md',
          id: 'Q.2.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.2.2/challengeDescription.md',
          id: 'Q.2.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        }
      ]
    },
    {
      id: 'q.3',
      label: 'Q.3',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Pair programming',
      textbookFilename: 'Q.3/Lesson.md',
      isComplete: false,
      nextLessonId: 'q.4',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.3.1/challengeDescription.md',
          id: 'Q.3.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.3.2/challengeDescription.md',
          id: 'Q.3.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
      ]
    },
    {
      id: 'q.4',
      label: 'Q.4',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Making and breaking promises',
      textbookFilename: 'Q.4/Lesson.md',
      isComplete: false,
      nextLessonId: 'q.5',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.4.1/challengeDescription.md',
          id: 'Q.4.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.4.2/challengeDescription.md',
          id: 'Q.4.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.4.3/challengeDescription.md',
          id: 'Q.4.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        }
      ]
    },
    {
      id: 'q.5',
      label: 'Q.5',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Hadamard transform',
      textbookFilename: 'Q.5/Lesson.md',
      isComplete: false,
      nextLessonId: 'q.6',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.5.1/challengeDescription.md',
          id: 'Q.5.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.5.2/challengeDescription.md',
          id: 'Q.5.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        }
      ]
    },
    {
      id: 'q.6',
      label: 'Q.6',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Deutsch-Jozsa',
      textbookFilename: 'Q.6/Lesson.md',
      isComplete: false,
      nextLessonId: null,
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.6.1/challengeDescription.md',
          id: 'Q.6.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'Q.6.2/challengeDescription.md',
          id: 'Q.6.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
      ]
    }
  ],
  edges: [
    { from: 'q.1', to: 'q.2' },
    { from: 'q.2', to: 'q.3' },
    { from: 'q.3', to: 'q.4' },
    { from: 'q.4', to: 'q.5' },
    { from: 'q.5', to: 'q.6' }
  ]
}

export default qGraph
