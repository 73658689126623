import PropTypes from 'prop-types'
import { useEffect, useState, useMemo } from 'react'
import { useDispatch, batch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  toggleLessonChallenges,
  toggleLessonTextbook,
  updateNodeState,
  updateCurrentLessonId
} from '../store/reducer'
import Challenge from './Challenge'

import './LessonChallenges.css'

function LessonChallenges(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [challengesOpacity, updateChallengesOpacity] = useState('opacity-0')
  const [challengesWidth, updateChallengesWidth] = useState('w-11/12 mx-auto')

  useEffect(() => {
    props.showLessonChallenges ? updateChallengesOpacity('opacity-100') : updateChallengesOpacity('opacity-0')
    props.showLessonTextbook ? updateChallengesWidth('w-full') : updateChallengesWidth('w-11/12 mx-auto')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useMemo(() => {
    if (props.showLessonChallenges) {
      setTimeout(() => {
        updateChallengesOpacity('opacity-100')
      }, 500)
    } else {
      updateChallengesOpacity('opacity-0')
    }
  }, [props.showLessonChallenges])

  useMemo(() => {
    if (props.showLessonTextbook) {
      setTimeout(() => {
        updateChallengesWidth('w-full')
      }, 100)
    } else {
      updateChallengesWidth('w-11/12 mx-auto')
    }
  }, [props.showLessonTextbook])

  useEffect(() =>{
    const chapter = props.lessonId[0]
    if (props.currentLessonIsComplete) {
      batch(() => {
        dispatch(updateNodeState({
          title: chapter,
          nodeId: props.lessonId,
          nodeState: 'complete'
        }))

        if (props.nextChapterIds) {
          dispatch(updateNodeState({
            title: 'chapters',
            nodeId: chapter,
            nodeState: 'complete'
          }))
        } else if (!props.nextLessonId) {
          dispatch(updateNodeState({
            title: 'chapters',
            nodeId: chapter,
            nodeState: 'complete'
          }))
        } else {
          dispatch(updateNodeState({
            title: 'chapters',
            nodeId: chapter,
            nodeState: 'partial'
          }))
        }
      })
    } else if (props.currentLessonIsStarted){
      batch(() => {
        dispatch(updateNodeState({
          title: chapter,
          nodeId: props.lessonId,
          nodeState: 'partial'
        }))

        dispatch(updateNodeState({
          title: 'chapters',
          nodeId: chapter,
          nodeState: 'partial'
        }))
      })
    } else {
      batch(() => {
        dispatch(updateNodeState({
          title: chapter,
          nodeId: props.lessonId,
          nodeState: 'enabled'
        }))
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentLessonIsComplete, props.currentLessonIsStarted])

  const goToNextLesson = () => {
    if (props.nextLessonId) { 
      dispatch(updateCurrentLessonId(props.nextLessonId)) 
      navigate(`/${props.nextLessonId}`)
    }
  }

  const enabledChallenges = () => {
    const shouldDisableChallenge = (challengeIndex) => {
      if (challengeIndex === 0) { // if it is the first challenge, enable the challenge
        return false
      } else { // otherwise, check if the previous challenge is complete
        const previousChallengeIndex = challengeIndex - 1
        return !props.challenges[previousChallengeIndex].isComplete
      }
    }

    return props.challenges.filter((challenge, challengeIndex) => !shouldDisableChallenge(challengeIndex))
  }

  const allChallengesCompleted = () => {
    return props.challenges.every((challenge) => challenge.isComplete)
  }

  const challengesCompletePercentage = () => {
    const completedChallenges = props.challenges.filter((challenge) => challenge.isComplete).length
    const totalChallenges = props.challenges.length

    return `${((completedChallenges / totalChallenges) * 100).toFixed()}%`
  }

  const challengesComplete = () => {
    const completedChallenges = props.challenges.filter((challenge) => challenge.isComplete).length
    const totalChallenges = props.challenges.length

    return `${completedChallenges}/${totalChallenges}`
  }

  return (
    <div className={`LessonChallenges ${props.showLessonChallenges ? challengesWidth : 'w-0'}`}>
      <div className={`LessonChallenges__content ${challengesOpacity}`}>
        <button
          className={`close-button ${props.showLessonTextbook ? 'opacity-100' : 'opacity-0 cursor-default'}`}
          onClick={() => dispatch(toggleLessonChallenges())}
          disabled={!props.showLessonTextbook}>
          <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3 5.71C17.91 5.32 17.28 5.32 16.89 5.71L12 10.59L7.11 5.7C6.72 5.31 6.09 5.31 5.7 5.7C5.31 6.09 5.31 6.72 5.7 7.11L10.59 12L5.7 16.89C5.31 17.28 5.31 17.91 5.7 18.3C6.09 18.69 6.72 18.69 7.11 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.11C18.68 6.73 18.68 6.09 18.3 5.71Z" fill="#23262C"/>
          </svg>
        </button>
        <div className="flex mb-2">
          <h2 className="my-auto h1">
            {props.lessonId.toUpperCase()} {props.lessonTitle}
          </h2>
          <button
            className={`LessonTextBook__show-textbook ${!props.showLessonTextbook ? 'opacity-100' : 'opacity-0 cursor-default'}`}
            onClick={() => dispatch(toggleLessonTextbook())}
            disabled={props.showLessonTextbook}>
            Show Textbook
          </button>
        </div>
        <div className="overflow-auto rounded pb-1">
          {enabledChallenges().map((challenge, challengeIndex) =>
            <Challenge
              key={`${props.lessonId}-${challengeIndex}`}
              challengeIndex={challengeIndex}
              lessonId={props.lessonId}
              challenge={challenge}
              allChallengesCompleted={allChallengesCompleted}>
            </Challenge>
          )}
        </div>
        <div className="flex gap-4 mt-6">
          {(props.currentLessonIsComplete && props.nextLessonId) &&
            <div className="my-auto">
              <button
                className="bg-white hover:bg-purple-1 text-purple-6 border-purple-6 border transition duration-200 text-white font-bold py-2 px-4 rounded"
                onClick={() => goToNextLesson()}>
                Next Lesson
              </button>
            </div>
          }
          <div className="flex flex-col my-auto flex-1">
            <div className="LessonChallenges__progress-bar">
              <div
                className="LessonChallenges__progress"
                style={{'width': challengesCompletePercentage() }}></div>
            </div>
            <div className="LessonChallenges__progress-percentage">{challengesComplete()} Complete</div>
          </div>
        </div>
      </div>
    </div>
  )
}

LessonChallenges.propTypes = {
  challenges: PropTypes.array.isRequired,
  currentLessonIsComplete: PropTypes.bool.isRequired,
  currentLessonIsStarted: PropTypes.bool.isRequired,
  showLessonChallenges: PropTypes.bool.isRequired,
  showLessonTextbook: PropTypes.bool.isRequired,
  lessonId: PropTypes.string.isRequired,
  lessonTitle: PropTypes.string.isRequired,
}

export default LessonChallenges
