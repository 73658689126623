import PropTypes from 'prop-types'
import { useState, useRef, useEffect, useMemo, useLayoutEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Graph from 'react-graph-vis'
import introJS from 'intro.js'
import ReactGA from 'react-ga4'

import XQCInfoModal from './XQCInfoModal'
import './NodeTree.css'

import {
  toggleGraph,
  toggleTutorialComplete
} from '../store/reducer'

const GRAPH_OPTIONS = {
  layout: {
    hierarchical: {
      levelSeparation: 100,
    },
  },
  nodes: {
    font: {
      size: 16,
      face: 'Noto Sans'
    },
    widthConstraint: 40,
    shape: 'circle',
    labelHighlightBold: false,
    borderWidthSelected: 1,
    color: {
      border: '#474852'
    },
  },
  edges: {
    chosen: false,
    color: {
      color: '#474852'
    },
    arrows: {
      to: false
    }
  },
  interaction: {
    dragNodes: false,
    selectConnectedEdges: false,
    hover: true,
    hoverConnectedEdges: false,
    zoomView: false,
    dragView: false,
  },
  physics: false
}

function NodeTree(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const graphRef = useRef()

  const [currentGraph, updateCurrentGraph] = useState({})
  const [graphOpacity, updateGraphOpacity] = useState('opacity-0')
  const [showInformationModal, toggleShowInformationModal] = useState(false)

  const tutorialComplete = useSelector((state) => state.tutorialComplete)

  useEffect(() => {
    const chapter = props.lessonId ? props.lessonId[0] : 'chapters'
    updateCurrentGraph(props.graphs.find((graph) => graph.title === chapter))
    props.showGraph ? updateGraphOpacity('opacity-100') : updateGraphOpacity('opacity-0')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lessonId])

  useLayoutEffect(() => {
    function refocusGraph() {
      if (graphRef.current) {
        graphRef.current.Network.redraw()
        graphRef.current.Network.fit()
      }
    }
    window.addEventListener('resize', refocusGraph)
    refocusGraph()
    return () => window.removeEventListener('resize', refocusGraph)
  }, []);

  useMemo(() => {
    if (props.currentLessonIsComplete || props.currentLessonIsStarted || props.lessonId) {
      const currentChapter = props.lessonId.split('.')[0]
      setTimeout(() => {
        // this guard is here incase a user completes a challenge but they have a different graph open
        if (currentChapter === currentGraph.title && graphRef.current) {
          graphRef.current.updateGraph()
          graphRef.current.Network.selectNodes([props.lessonId])

          if (currentGraph.nodes.length > 8) { graphRef.current.Network.fit() }
        // If the user has the chapters menu open when the last lesson is complete
        // we want the chapters menu to update to reflect the newly unlocked chapter nodes
        } else if (props.currentLessonIsComplete && (currentGraph.title === 'chapters')) {
          const currentLessonNodes = props.graphs.find((graph) => graph.title === props.lessonId[0]).nodes
          const currentLessonNode = currentLessonNodes.find((node) => node.id === props.lessonId)
          const currentLessonNodeIndex = currentLessonNodes.indexOf(currentLessonNode)

          if (currentLessonNodes.length - 1 === currentLessonNodeIndex && graphRef.current) { graphRef.current.updateGraph() }
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentLessonIsComplete, props.currentLessonIsStarted, props.lessonId])

  useEffect(() => {
    if (graphRef.current && graphRef.current.Network) {
      if (currentGraph.title !== 'chapters') {
        if (props.lessonId && (props.lessonId[0] === currentGraph.title)) { graphRef.current.Network.selectNodes([props.lessonId]) }
      } 
      graphRef.current.Network.fit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGraph])

  useMemo(() => {
    if (props.showGraph && graphRef.current) {
      setTimeout(() => {
        updateGraphOpacity('opacity-100')
        graphRef.current.Network.redraw()
        graphRef.current.Network.fit()
      }, 500)
    } else {
      updateGraphOpacity('opacity-0')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showGraph])

  const highlightCurrentLessonNode = () => {
    if (props.lessonId && props.lessonId[0] === currentGraph.title && graphRef.current) {
      graphRef.current.Network.selectNodes([props.lessonId])
    }
  }

  const events = {
    click(e) {
      if (!e.nodes.length) { highlightCurrentLessonNode() }
    },
    selectNode(event) {
      const { nodes } = event
      const selectedNode = currentGraph.nodes.find((node) => node.id === nodes[0])
      
      if (!selectedNode.chosen) {
        highlightCurrentLessonNode()
        return
      }

      const [chapter, lesson] = nodes[0].split('.')

      if (lesson) {
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: `Lesson: ${nodes}`,
            action: `Click Lesson Node: ${nodes}`
          })
        }
        navigate(`/${selectedNode.label}`)
      } else {
        if (!tutorialComplete) {
          setTimeout(() => {
            introJS().addHints()
          }, 250)
        }
        updateCurrentGraph(props.graphs.find((graph) => graph.title === chapter))
      }
      graphRef.current.Network.fit()
    }
  }

  const backToChapters = () => {
    if (!tutorialComplete) {
      dispatch(toggleTutorialComplete())
      introJS().hideHints()
    }
    updateCurrentGraph(props.graphs.find((graph) => graph.title === 'chapters'))
  }

  return (
    <div className={`NodeTree hidden md:grid lg:grid xl:grid ${!props.showGraph ? 'NodeTree--closed' : ''}`}>
      <button
        className={`absolute top-1 z-20 cursor-pointer transition-all duration-500 ease-linear ${props.showGraph ? 'left-1' : 'left-3'}`}
        onClick={() => toggleShowInformationModal(true)}>
        <img
          className="h-6 w-6"
          src="/xanadu_logo.svg"
          alt="Xanadu Logo"
        />
      </button>
      <div className={`absolute right-1 top-1 z-10 ${graphOpacity}`}>
        {currentGraph.title !== 'chapters' &&
          <button
            id="backToChapters"
            data-hint="Click the 'Back to Modules' button to go back to the main module graph and start exploring other modules."
            data-hintposition="bottom-right"
            className="NodeTree__buttons bg-white hover:bg-purple-1 text-purple-6 border-purple-6 border rounded text-xs left-1 top-1 z-10 mb-1 flex"
            onClick={() => backToChapters()}>
            <svg width="20" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.005 16.165L11.125 12.285L15.005 8.405C15.395 8.015 15.395 7.385 15.005 6.995C14.615 6.605 13.985 6.605 13.595 6.995L9.005 11.585C8.615 11.975 8.615 12.605 9.005 12.995L13.595 17.585C13.985 17.975 14.615 17.975 15.005 17.585C15.385 17.195 15.395 16.555 15.005 16.165Z" fill="#4054C7"/>
            </svg>
            <span className="my-auto pr-2">Back to Modules</span>
          </button>
        }
      </div>
      <div
        className={`NodeTree__graph ${graphOpacity}`}
        data-intro="Click the T node, then the T.1 node to begin the tutorial."
        data-title="Welcome"
        data-step="1">
        {Object.keys(currentGraph).length &&
          <Graph
            ref={graphRef}
            graph={currentGraph}
            options={GRAPH_OPTIONS}
            events={events}>
          </Graph>
        }
      </div>
      <div className="flex">
        <button
          className="NodeTree__toggle"
          onClick={() => dispatch(toggleGraph())}>
          <svg className="h-3 w-3 m-auto" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.0914 2.8825L3.6814 0.2925C4.0714 -0.0975 4.7014 -0.0975 5.0914 0.2925L7.6814 2.8825C8.3114 3.5125 7.8614 4.5925 6.9714 4.5925H1.7914C0.901399 4.5925 0.461399 3.5125 1.0914 2.8825Z" fill="#23262C"/>
          </svg>
        </button>
      </div>
      <XQCInfoModal
        showInformationModal={showInformationModal}
        toggleShowInformationModal={toggleShowInformationModal} />
    </div>
  );
}

NodeTree.propTypes = {
  showGraph: PropTypes.bool.isRequired,
  graphs: PropTypes.array.isRequired,
  lessonId: PropTypes.string,
  currentLessonIsComplete: PropTypes.bool.isRequired,
  currentLessonIsStarted: PropTypes.bool.isRequired,
}

export default memo(NodeTree)
