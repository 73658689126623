import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useState, useEffect, memo, useMemo } from 'react'

import {
  toggleLessonChallenges,
  toggleLessonTextbook
} from '../store/reducer'
import Markdown from './Markdown'

function LessonTextBook(props) {
  const dispatch = useDispatch()
  const [textbookOpacity, updateTextbookOpacity] = useState('opacity-0')
  const [textbookWidth, updateTextbookWidth] = useState('w-11/12 mx-auto')

  useEffect(() => {
    props.showLessonTextbook ? updateTextbookOpacity('opacity-100') : updateTextbookOpacity('opacity-0')
    props.showLessonChallenges ? updateTextbookWidth('w-full') : updateTextbookWidth('w-11/12 mx-auto')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useMemo(() => {
    if (props.lessonId) {
      const el = document.querySelector('.LessonTextBook .Markdown')
      if (el) el.scrollIntoView()
    }
  }, [props.lessonId])

  useMemo(() => {
    if (props.showLessonTextbook) {
      setTimeout(() => {
        updateTextbookOpacity('opacity-100')
      }, 500)
    } else {
      updateTextbookOpacity('opacity-0')
    }
  }, [props.showLessonTextbook])

  useMemo(() => {
    if (props.showLessonChallenges) {
      setTimeout(() => {
        updateTextbookWidth('w-full')
      }, 100)
    } else {
      updateTextbookWidth('w-11/12 mx-auto')
    }
  }, [props.showLessonChallenges])

  return (
    <div className={`LessonTextBook ${props.showLessonTextbook ? textbookWidth : 'w-0'}`}>
      <div className={`LessonTextBook__content ${textbookOpacity}`}>
        <button
          className={`close-button ${props.showLessonChallenges ? 'opacity-100' : 'opacity-0 cursor-default'}`}
          onClick={() => dispatch(toggleLessonTextbook())}
          disabled={!props.showLessonChallenges}>
          <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3 5.71C17.91 5.32 17.28 5.32 16.89 5.71L12 10.59L7.11 5.7C6.72 5.31 6.09 5.31 5.7 5.7C5.31 6.09 5.31 6.72 5.7 7.11L10.59 12L5.7 16.89C5.31 17.28 5.31 17.91 5.7 18.3C6.09 18.69 6.72 18.69 7.11 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.11C18.68 6.73 18.68 6.09 18.3 5.71Z" fill="#23262C"/>
          </svg>
        </button>
        <div className="flex mb-2">
          <h2 className="my-auto h1">
            {props.lessonId.toUpperCase()} {props.lessonTitle}
          </h2>
          <button
            className={`LessonTextBook__show-challenges ${!props.showLessonChallenges ? 'opacity-100' : 'opacity-0 cursor-default'}`}
            onClick={() => dispatch(toggleLessonChallenges())}
            disabled={props.showLessonChallenges}>
            Show Challenge
          </button>
        </div>
        <div className="overflow-auto rounded">
          <Markdown filename={props.textbookFilename} />
        </div>
      </div>
    </div>
  )
}

LessonTextBook.propTypes = {
  showLessonChallenges: PropTypes.bool.isRequired,
  showLessonTextbook: PropTypes.bool.isRequired,
  showGraph: PropTypes.bool.isRequired,
  textbookFilename: PropTypes.string.isRequired,
  lessonTitle: PropTypes.string.isRequired,
  lessonId: PropTypes.string.isRequired
}

export default memo(LessonTextBook)
