import { NODE_STATES } from '../reducer'

const fGraph = {
  title: 'f',
  nodes: [
    {
      id: 'f.1',
      label: 'F.1',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Changing Perspectives',
      textbookFilename: 'lessons/F.1/Lesson.md',
      isComplete: false,
      nextLessonId: 'f.2',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.1.1/challengeDescription.md',
          id: 'F.1.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.1.2/challengeDescription.md',
          id: 'F.1.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.1.3/challengeDescription.md',
          id: 'F.1.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.1.4/challengeDescription.md',
          id: 'F.1.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        }
      ]
    },
    {
      id: 'f.2',
      label: 'F.2',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Quantum Fourier transform',
      textbookFilename: 'lessons/F.2/Lesson.md',
      isComplete: false,
      nextLessonId: 'f.3',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.2.1/challengeDescription.md',
          id: 'F.2.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.2.2/challengeDescription.md',
          id: 'F.2.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.2.3/challengeDescription.md',
          id: 'F.2.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        }
      ]
    },
    {
      id: 'f.3',
      label: 'F.3',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Connecting the dots',
      textbookFilename: 'lessons/F.3/Lesson.md',
      isComplete: false,
      nextLessonId: null,
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.3.1/challengeDescription.md',
          id: 'F.3.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.3.2/challengeDescription.md',
          id: 'F.3.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.3.3/challengeDescription.md',
          id: 'F.3.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.3.4/challengeDescription.md',
          id: 'F.3.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/F.3.5/challengeDescription.md',
          id: 'F.3.5',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        }
      ]
    },
    
  ],
  edges: [
    { from: 'f.1', to: 'f.2' },
    { from: 'f.2', to: 'f.3' }
  ]
}

export default fGraph
