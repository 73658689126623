import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import loadScript from 'load-script'
import WebFont from 'webfontloader'
import introJS from 'intro.js'
import ReactGA from 'react-ga4'
import '../node_modules/intro.js/introjs.css'

import Lesson from './components/Lesson'
import { updateCurrentLessonId, updateMathJax } from './store/reducer'

const MATHJAX_SCRIPT = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML'
const MATHJAX_OPTIONS = {
  tex2jax: {
    inlineMath: [ ['$','$'], ['\\(','\\)'] ]
  },
  showMathMenu: false,
  showMathMenuMSIE: false
}

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {lessonId} = useParams()

  const state = useSelector((state) => {
    return {
      tutorialComplete: state.tutorialComplete,
      currentLessonId: state.currentLessonId
    }
  })

  useEffect(() => {
    if (state.currentLessonId !== lessonId?.toLowerCase()) {
      dispatch(updateCurrentLessonId(lessonId?.toLowerCase()))
    }

    if(lessonId && lessonId === lessonId.toLowerCase()) {
      navigate(`/${lessonId.toUpperCase()}`, { replace: true })
    }
  }, [state.currentLessonId, lessonId, dispatch, navigate])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-C5SXWJZFQ3')
      ReactGA.pageview(window.location.hostname)
    }

    loadScript(MATHJAX_SCRIPT, () => {
      if (window.MathJax) {
        window.MathJax.Hub.Config(MATHJAX_OPTIONS)
        dispatch(updateMathJax(window.MathJax))
      }
    });

    WebFont.load({
      google: {
        families: ['Inter', 'Noto Sans']
      }
    })

    if (!state.tutorialComplete && !state.currentLessonId) {
      setTimeout(() => {
        introJS().setOptions({
          showButtons: false,
          showBullets: false,
        })
        .start()
      }, 1500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return (<Lesson />)
}

export default App;
