import { createStore, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'

import { reducer } from './reducer'
import { persistConfig } from './migrations'
import state from './state'

const enhancers = compose(
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = createStore(
  persistedReducer,
  state,
  enhancers
)
export const persistor = persistStore(store)
