import { NODE_STATES } from '../reducer'

const iGraph = {
  title: 'i',
  nodes: [
    {
      id: 'i.1',
      label: 'I.1',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      title: 'All about qubits',
      textbookFilename: 'lessons/I.1/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.2',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.1.1/challengeDescription.md',
          id: 'I.1.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.1.2/challengeDescription.md',
          id: 'I.1.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.1.3/challengeDescription.md',
          id: 'I.1.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.1.4/challengeDescription.md',
          id: 'I.1.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.1.5/challengeDescription.md',
          id: 'I.1.5',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.2',
      label: 'I.2',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Quantum circuits',
      textbookFilename: 'lessons/I.2/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.3',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.2.1/challengeDescription.md',
          id: 'I.2.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.2.2/challengeDescription.md',
          id: 'I.2.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.2.3/challengeDescription.md',
          id: 'I.2.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.2.4/challengeDescription.md',
          id: 'I.2.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.3',
      label: 'I.3',
      color: NODE_STATES['enabled'].color,
      font: NODE_STATES['enabled'].font,
      chosen: NODE_STATES['enabled'].chosen,
      title: 'Unitary matrices',
      textbookFilename: 'lessons/I.3/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.4',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.3.1/challengeDescription.md',
          id: 'I.3.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.3.2/challengeDescription.md',
          id: 'I.3.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.3.3/challengeDescription.md',
          id: 'I.3.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.4',
      label: 'I.4',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      title: 'X and H',
      textbookFilename: 'lessons/I.4/Lesson.md',
      font: NODE_STATES['enabled'].font,
      isComplete: false,
      nextLessonId: 'i.5',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.4.1/challengeDescription.md',
          id: 'I.4.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.4.2/challengeDescription.md',
          id: 'I.4.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.4.3/challengeDescription.md',
          id: 'I.4.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.4.4/challengeDescription.md',
          id: 'I.4.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.5',
      label: 'I.5',
      color: NODE_STATES['enabled'].color,
      font: NODE_STATES['enabled'].font,
      chosen: NODE_STATES['enabled'].chosen,
      title: "It's just a phase",
      textbookFilename: 'lessons/I.5/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.6',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.5.1/challengeDescription.md',
          id: 'I.5.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.5.2/challengeDescription.md',
          id: 'I.5.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.5.3/challengeDescription.md',
          id: 'I.5.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.5.4/challengeDescription.md',
          id: 'I.5.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.5.5/challengeDescription.md',
          id: 'I.5.5',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.6',
      label: 'I.6',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'From a different angle',
      textbookFilename: 'lessons/I.6/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.7',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.6.1/challengeDescription.md',
          id: 'I.6.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.6.2/challengeDescription.md',
          id: 'I.6.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.6.3/challengeDescription.md',
          id: 'I.6.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.6.4/challengeDescription.md',
          id: 'I.6.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.7',
      label: 'I.7',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Universal gate sets',
      textbookFilename: 'lessons/I.7/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.8',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.7.1/challengeDescription.md',
          id: 'I.7.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.7.2/challengeDescription.md',
          id: 'I.7.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.7.3/challengeDescription.md',
          id: 'I.7.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.7.4/challengeDescription.md',
          id: 'I.7.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.8',
      label: 'I.8',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Prepare yourself',
      textbookFilename: 'lessons/I.8/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.9',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.8.1/challengeDescription.md',
          id: 'I.8.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.8.2/challengeDescription.md',
          id: 'I.8.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.8.3/challengeDescription.md',
          id: 'I.8.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.9',
      label: 'I.9',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Measurements',
      textbookFilename: 'lessons/I.9/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.10',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.9.1/challengeDescription.md',
          id: 'I.9.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.9.2a/challengeDescription.md',
          id: 'I.9.2a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.9.2b/challengeDescription.md',
          id: 'I.9.2b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.10',
      label: 'I.10',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'What did you expect?',
      textbookFilename: 'lessons/I.10/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.11',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.10.1/challengeDescription.md',
          id: 'I.10.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.10.2/challengeDescription.md',
          id: 'I.10.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.10.3/challengeDescription.md',
          id: 'I.10.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.10.4/challengeDescription.md',
          id: 'I.10.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
      ]
    },
    {
      id: 'i.11',
      label: 'I.11',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Multi-qubit systems',
      textbookFilename: 'lessons/I.11/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.12',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.11.1/challengeDescription.md',
          id: 'I.11.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.11.2/challengeDescription.md',
          id: 'I.11.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.11.3/challengeDescription.md',
          id: 'I.11.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.11.4/challengeDescription.md',
          id: 'I.11.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.12',
      label: 'I.12',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'All tied up',
      textbookFilename: 'lessons/I.12/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.13',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.12.1/challengeDescription.md',
          id: 'I.12.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.12.2/challengeDescription.md',
          id: 'I.12.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.12.3/challengeDescription.md',
          id: 'I.12.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'i.13',
      label: 'I.13',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: "We've got it under control",
      textbookFilename: 'lessons/I.13/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.14',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.13.1/challengeDescription.md',
          id: 'I.13.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.13.2/challengeDescription.md',
          id: 'I.13.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.13.3/challengeDescription.md',
          id: 'I.13.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.13.4/challengeDescription.md',
          id: 'I.13.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.13.5/challengeDescription.md',
          id: 'I.13.5',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.13.6/challengeDescription.md',
          id: 'I.13.6',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
     },
     {
      id: 'i.14',
      label: 'I.14',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Multi-qubit gate challenge',
      textbookFilename: 'lessons/I.14/Lesson.md',
      isComplete: false,
      nextLessonId: 'i.15',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.14.1/challengeDescription.md',
          id: 'I.14.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.14.2/challengeDescription.md',
          id: 'I.14.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.14.3/challengeDescription.md',
          id: 'I.14.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
     },
     {
      id: 'i.15',
      label: 'I.15',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Quantum teleportation',
      textbookFilename: 'lessons/I.15/Lesson.md',
      isComplete: false,
      nextLessonId: null,
      nextChapterIds: ['a', 'h'],
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.15.1a/challengeDescription.md',
          id: 'I.15.1a',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.15.1b/challengeDescription.md',
          id: 'I.15.1b',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.15.2/challengeDescription.md',
          id: 'I.15.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.15.3/challengeDescription.md',
          id: 'I.15.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.15.4/challengeDescription.md',
          id: 'I.15.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.15.5/challengeDescription.md',
          id: 'I.15.5',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/I.15.6/challengeDescription.md',
          id: 'I.15.6',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
  ],
  edges: [
    { from: 'i.1', to: 'i.2' },
    { from: 'i.2', to: 'i.3' },
    { from: 'i.3', to: 'i.4' },
    { from: 'i.4', to: 'i.5' },
    { from: 'i.5', to: 'i.6' },
    { from: 'i.6', to: 'i.7' },
    { from: 'i.7', to: 'i.8' },
    { from: 'i.8', to: 'i.9' },
    { from: 'i.9', to: 'i.10' },
    { from: 'i.10', to: 'i.11' },
    { from: 'i.11', to: 'i.12' },
    { from: 'i.12', to: 'i.13' },
    { from: 'i.13', to: 'i.14' },
    { from: 'i.14', to: 'i.15' },
  ]
}

export default iGraph
