import Markdown from '../components/Markdown'

function XanaduDetails() {
  return (
    <div className="m-0 md:m-4 lg:m-4 xl:m-4 flex flex-col bg-white rounded shadow p-6">
      <img
        src="/xanadu_logo.svg"
        alt="Xanadu Logo"
        className="mx-auto mb-8 mt-2 max-h-96"
      />
      <h1 className="mx-auto mb-8 text-4xl tracking-wider text-center">XANADU QUANTUM CODEBOOK</h1>
      <div className="mx-auto max-w-6xl overflow-auto hidden md:block lg:block xl:block">
        <Markdown filename="xanadu-information.md" />
      </div>
      <div className="text-xl md:hidden lg:hidden xl:hidden overflow-scroll">
        Welcome to the Codebook! Due to the interactive nature of the coding challenges, the Codebook is not currently available on mobile browsers. Please visit us from your desktop or laptop.
      </div>
    </div>
  )
}

export default XanaduDetails
