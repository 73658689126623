import { NODE_STATES } from '../reducer'

const pGraph = {
  title: 'p',
  nodes: [
    {
      id: 'p.1',
      label: 'P.1',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      title: 'Catch the phase',
      textbookFilename: 'lessons/P.1/Lesson.md',
      isComplete: false,
      nextLessonId: 'p.2',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.1.1/challengeDescription.md',
          id: 'P.1.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.1.2/challengeDescription.md',
          id: 'P.1.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.1.3/challengeDescription.md',
          id: 'P.1.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        }
      ]
    },
    {
      id: 'p.2',
      label: 'P.2',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'It\'s not just a phase',
      textbookFilename: 'lessons/P.2/Lesson.md',
      isComplete: false,
      nextLessonId: 'p.3',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.2.1/challengeDescription.md',
          id: 'P.2.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.2.2/challengeDescription.md',
          id: 'P.2.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.2.3/challengeDescription.md',
          id: 'P.2.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.2.4/challengeDescription.md',
          id: 'P.2.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.2.5/challengeDescription.md',
          id: 'P.2.5',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        }
      ]
    },
    {
      id: 'p.3',
      label: 'P.3',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Let\'s be rational',
      textbookFilename: 'lessons/P.3/Lesson.md',
      isComplete: false,
      nextLessonId: 'p.4',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.3.1/challengeDescription.md',
          id: 'P.3.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.3.2/challengeDescription.md',
          id: 'P.3.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
      ]
    },
    {
      id: 'p.4',
      label: 'P.4',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'A mixed bag',
      textbookFilename: 'lessons/P.4/Lesson.md',
      isComplete: false,
      nextLessonId: 'p.5',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.4.1/challengeDescription.md',
          id: 'P.4.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.4.2/challengeDescription.md',
          id: 'P.4.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.4.3/challengeDescription.md',
          id: 'P.4.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/P.4.4/challengeDescription.md',
          id: 'P.4.4',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
      ]
    }
  ],
  edges: [
    { from: 'p.1', to: 'p.2' },
    { from: 'p.2', to: 'p.3' },
    { from: 'p.3', to: 'p.4' },
    { from: 'p.4', to: 'p.5' },
    { from: 'p.5', to: 'p.6' }
  ]
}

export default pGraph