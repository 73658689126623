import { NODE_STATES } from '../reducer'

const tutorialGraph = {
  title: 't',
  nodes: [
    {
      id: 't.1',
      label: 'T.1',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      title: 'Tutorial',
      textbookFilename: 'lessons/TUTORIAL.1/Lesson.md',
      isComplete: false,
      nextLessonId: null,
      nextChapterIds: ['i'],
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/TUTORIAL.1.1/challengeDescription.md',
          id: 'T.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/TUTORIAL.1.2/challengeDescription.md',
          id: 'T.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/TUTORIAL.1.3/challengeDescription.md',
          id: 'T.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    }
  ],
  edges: []
}

export default tutorialGraph
