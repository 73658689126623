import { useRef, useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import AceEditor from "react-ace";
import axios from "axios";
import { VegaLite } from "react-vega";
import { useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-sqlserver";

import Markdown from "./Markdown";
import {
  toggleChallengeComplete,
  toggleChallengeIsCorrect,
  updateChallengeAnswer,
  updateChallengeQuestionTemplate,
  updateChallengePlottingData,
  updateChallengeMessage,
  updateUserOutput,
  toggleLessonComplete,
} from "../store/reducer";

function Challenge(props) {
  const dispatch = useDispatch();
  const editorRef = useRef();
  const challengeMessageRef = useRef();

  const [loading, toggleLoading] = useState(false);

  const reportError = (
    status = "Status N/A",
    statusText = "Status Text N/A",
    errorMsg
  ) => {
    if (process.env.NODE_ENV === "production") {
      Sentry.withScope((scope) => {
        scope.setExtra("Lesson ID", props.lessonId);
        scope.setExtra("Challenge", props.challenge);
        Sentry.captureMessage(`${status} (${statusText}): ${errorMsg}`);
      });
    }
  };

  useEffect(() => {
    toggleLoading(true);

    // when a user opens a lesson,
    // if this is the first time the user has opened the lesson
    // we fetch the question template from the Lambda
    // and pass the response to the text editor and update the store
    if (!props.challenge.questionTemplate) {
      axios
        .post(process.env.REACT_APP_LAMBDA_URL, {
          return_template: 1,
          challenge_id: props.challenge.id,
        })
        .then((response) => {
          dispatch(
            updateChallengeQuestionTemplate({
              questionTemplate: response.data.msg || "",
              lessonId: props.lessonId,
              challengeIndex: props.challengeIndex,
            })
          );

          if (response.data.msg) {
            editorRef.current.editor.setValue(response.data.msg, 1);
          } else {
            const errorMsg =
              "There was an error fetching the question template";
            dispatch(
              updateChallengeMessage({
                challengeMessage: errorMsg,
                lessonId: props.lessonId,
                challengeIndex: props.challengeIndex,
              })
            );
            reportError(response.status, response.statusText, errorMsg);
          }

          toggleLoading(false);
        })
        .catch((error) => {
          let errorMsg = error.toJSON ? error.toJSON().message : error;
          errorMsg =
            typeof errorMsg === "string"
              ? errorMsg
              : "There was an internal error";
          dispatch(
            updateChallengeMessage({
              challengeMessage: errorMsg,
              lessonId: props.lessonId,
              challengeIndex: props.challengeIndex,
            })
          );

          if (
            error.request &&
            error.request.status &&
            error.request.statusText
          ) {
            reportError(
              error.request.status,
              error.request.statusText,
              errorMsg
            );
          } else {
            reportError(errorMsg);
          }

          toggleLoading(false);
        });
      // otherwise, we update the editor with the user's existing question progress or the already fetched question
    } else {
      editorRef.current.editor.setValue(
        props.challenge.answer || props.challenge.questionTemplate,
        1
      );
      toggleLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lessonId]);

  const resetChallenge = () => {
    // When a user clicks the Reset button, it replaces user progress with the initial question template
    // as well as removing any plotting data, user output, and correct or error messages
    editorRef.current.editor.setValue(props.challenge.questionTemplate, 1);
    if (props.challenge.plottingData) {
      dispatch(
        updateChallengePlottingData({
          plottingData: null,
          lessonId: props.lessonId,
          challengeIndex: props.challengeIndex,
        })
      );
    }

    dispatch(
      updateChallengeMessage({
        challengeMessage: "",
        lessonId: props.lessonId,
        challengeIndex: props.challengeIndex,
      })
    );

    dispatch(
      updateUserOutput({
        userOutput: "",
        lessonId: props.lessonId,
        challengeIndex: props.challengeIndex,
      })
    );
    
    dispatch(
      toggleChallengeComplete({
        lessonId: props.lessonId,
        challengeIndex: props.challengeIndex,
        isComplete: false
      })
    );

    dispatch(
      toggleChallengeIsCorrect({
        lessonId: props.lessonId,
        challengeIndex: props.challengeIndex,
        isCorrect: false
      })
    );

    dispatch(
      toggleLessonComplete({
        lessonId: props.lessonId,
        isComplete: false
      })
    );
  };

  useEffect(() => {
    // when the challenge message changes (on challenge submit)
    // the screen wll scroll to the message
    if (props.challenge.challengeMessage) {
      challengeMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [props.challenge.challengeMessage]);

  /** Makes sure that error/success messages are styled correctly for challenges that have already been completed. */
  useEffect(() => {
    if(props.challenge.isCorrect == null) {
      dispatch(
        toggleChallengeIsCorrect({
          lessonId: props.lessonId,
          challengeIndex: props.challengeIndex,
          isCorrect: props.challenge.isComplete
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitChallenge = () => {
    toggleLoading(true);

    const answer = editorRef.current.editor.getValue();
    axios
      .post(process.env.REACT_APP_LAMBDA_URL, {
        answer: answer,
        challenge_id: props.challenge.id,
      })
      .then((response) => {
        const { msg, success, data, user_output } = response.data;

        toggleLoading(false);
        dispatch(
          updateChallengeMessage({
            challengeMessage: msg,
            lessonId: props.lessonId,
            challengeIndex: props.challengeIndex,
          })
        );

        dispatch(
          updateUserOutput({
            userOutput: user_output || "",
            lessonId: props.lessonId,
            challengeIndex: props.challengeIndex,
          })
        );

        if (data) {
          const plottingData = JSON.parse(data);
          delete plottingData.$schema;
          dispatch(
            updateChallengePlottingData({
              plottingData,
              lessonId: props.lessonId,
              challengeIndex: props.challengeIndex,
            })
          );
        }

        if (success) {
          dispatch(
            toggleChallengeComplete({
              lessonId: props.lessonId,
              challengeIndex: props.challengeIndex,
              isComplete: true
            })
          );

          dispatch(
            toggleChallengeIsCorrect({
              lessonId: props.lessonId,
              challengeIndex: props.challengeIndex,
              isCorrect: true
            })
          );

          if (props.allChallengesCompleted()) {
            dispatch(
              toggleLessonComplete({
                lessonId: props.lessonId,
                isComplete: true
              })
            );
          }
        }

        if(!success) {
          dispatch(
            toggleChallengeIsCorrect({
              lessonId: props.lessonId,
              challengeIndex: props.challengeIndex,
              isCorrect: false
            })
          );
        }
      })
      .catch((error) => {
        let errorMsg = error.toJSON ? error.toJSON().message : error;
        errorMsg =
          typeof errorMsg === "string"
            ? errorMsg
            : "There was an internal error";

        dispatch(
          updateChallengeMessage({
            challengeMessage: errorMsg,
            lessonId: props.lessonId,
            challengeIndex: props.challengeIndex,
          })
        );
        dispatch(
          updateUserOutput({
            userOutput: "",
            lessonId: props.lessonId,
            challengeIndex: props.challengeIndex,
          })
        );

        if (error.request && error.request.status && error.request.statusText) {
          reportError(error.request.status, error.request.statusText, errorMsg);
        } else {
          reportError(errorMsg);
        }
        toggleLoading(false);
      });
  };

  const updateChallengeAnswerHandler = () => {
    // This onChange handler ensures that challenge progress is in sync within
    // the editor component and our store
    const answer = editorRef.current.editor.getValue();
    dispatch(
      updateChallengeAnswer({
        answer,
        lessonId: props.lessonId,
        challengeIndex: props.challengeIndex,
      })
    );
  };

  return (
    <div className="relative">
      {props.challenge.descriptionFilename && (
        <Markdown filename={props.challenge.descriptionFilename} />
      )}
      <div className="Editor relative pt-4">
        {loading && (
          <div className="challenge-disabled rounded absolute w-full h-full bg-gray-900 bg-opacity-70 z-10 cursor-not-allowed flex top-0 left-0">
            <div className="text-white font-bold text-xl animate-pulse m-auto">
              Loading...
            </div>
          </div>
        )}
        <AceEditor
          mode="python"
          theme="sqlserver"
          name="coding-challenge"
          readOnly={loading}
          editorProps={{ $blockScrolling: true }}
          onChange={() => updateChallengeAnswerHandler()}
          width="91.666667%"
          height="500px"
          fontSize={14}
          showPrintMargin={false}
          showGutter={true}
          ref={editorRef}
          className={`rounded mb-4 mx-auto filter drop-shadow-lg`}
        />
        <div className="Editor__buttons flex justify-end space-x-3 w-11/12 mx-auto">
          <button
            className={`text-white py-1 px-3 rounded transition duration-200 ${
              loading
                ? "bg-red-900 cursor-not-allowed"
                : "bg-red-6 hover:bg-red-4"
            }`}
            onClick={() => resetChallenge()}
            disabled={loading}
          >
            Reset
          </button>
          <button
            className={`text-white py-1 px-3 rounded transition duration-200 ${
              loading
                ? "bg-green-900 cursor-not-allowed"
                : "bg-green-6 hover:bg-green-5"
            }`}
            onClick={() => submitChallenge()}
            disabled={loading}
          >
            Submit
          </button>
        </div>
        {props.challenge.challengeMessage && (
          <div
            ref={challengeMessageRef}
            className={`Editor__results whitespace-pre-line mb-4 rounded border p-4 mt-4 text-center w-11/12 mx-auto ${
              props.challenge.isCorrect
                ? "bg-green-1 border-green-6 text-green-6"
                : "bg-red-100 border-red-6 text-red-6"
            }`}
          >
            {props.challenge.challengeMessage}
          </div>
        )}
        {props.challenge.userOutput && (
          <details open>
            <summary className="px-6">
              <i>User Output</i>
            </summary>
            <div className="Editor__results whitespace-pre-line mb-4 rounded border p-4 mt-4 text-center w-11/12 mx-auto bg-info-6 border-info-1 text-info-1">
              {props.challenge.userOutput}
            </div>
          </details>
        )}
        {props.challenge.plottingData && (
          <div className="flex">
            <VegaLite
              className="mx-auto"
              actions={false}
              spec={props.challenge.plottingData}
            />
          </div>
        )}
      </div>
    </div>
  );
}

Challenge.propTypes = {
  lessonId: PropTypes.string.isRequired,
  challengeIndex: PropTypes.number.isRequired,
  allChallengesCompleted: PropTypes.func.isRequired,
  challenge: PropTypes.shape({
    descriptionFilename: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isComplete: PropTypes.bool.isRequired,
    isCorrect: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.number.isRequired]),
    answer: PropTypes.string.isRequired,
    questionTemplate: PropTypes.string.isRequired,
    plottingData: PropTypes.object,
    challengeMessage: PropTypes.string.isRequired,
    userOutput: PropTypes.string,
  }),
};

export default memo(Challenge);
