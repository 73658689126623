export const NODE_STATES = {
  complete: {
    color: {
      background: "#CEF5DB",
      border: "#26BF5A",
      highlight: { background: "#5CD686", border: "#0BA13E" },
      hover: { background: "#5CD686", border: "#0BA13E" },
    },
    font: {
      color: "#474852",
    },
    chosen: true,
  },
  partial: {
    color: {
      background: "#FFEDC2",
      border: "#FFCE5C",
      highlight: { background: "#FFDA85", border: "#FFB60A" },
      hover: { background: "#FFDA85", border: "#FFB60A" },
    },
    font: {
      color: "#474852",
    },
    chosen: true,
  },
  enabled: {
    color: {
      background: "white",
      border: "#474852",
      highlight: { background: "#67CEE5", border: "#0298BA" },
      hover: { background: "#D7F6FC", border: "#0298BA" },
    },
    font: {
      color: "#474852",
    },
    chosen: true,
  },
  disabled: {
    color: {
      background: "#F0F1F5",
      border: "#989BA8",
    },
    font: {
      color: "#989BA8",
    },
    chosen: false,
  },
};

export const updateMathJax = (value) => {
  return {
    type: "UPDATE_MATHJAX",
    value,
  };
};

export const toggleGraph = () => {
  return {
    type: "TOGGLE_GRAPH",
  };
};

export const toggleLessonChallenges = () => {
  return {
    type: "TOGGLE_LESSON_CHALLENGES",
  };
};

export const toggleLessonTextbook = () => {
  return {
    type: "TOGGLE_LESSON_TEXTBOOK",
  };
};

export const toggleChallengeComplete = (value) => {
  return {
    type: "TOGGLE_CHALLENGE_COMPLETE",
    value,
  };
};

export const toggleChallengeIsCorrect = (value) => {
  return {
    type: "TOGGLE_CHALLENGE_IS_CORRECT",
    value,
  };
};

export const toggleLessonComplete = (value) => {
  return {
    type: "TOGGLE_LESSON_COMPLETE",
    value,
  };
};

export const updateChallengeAnswer = (value) => {
  return {
    type: "UPDATE_CHALLENGE_ANSWER",
    value,
  };
};

export const updateUserOutput = (value) => {
  return {
    type: "UPDATE_USER_OUTPUT",
    value,
  };
};

export const updateChallengeQuestionTemplate = (value) => {
  return {
    type: "UPDATE_CHALLENGE_QUESTION_TEMPLATE",
    value,
  };
};

export const updateChallengePlottingData = (value) => {
  return {
    type: "UPDATE_CHALLENGE_PLOTTING_DATA",
    value,
  };
};

export const updateChallengeMessage = (value) => {
  return {
    type: "UPDATE_CHALLENGE_MESSAGE",
    value,
  };
};

export const updateCurrentLessonId = (value) => {
  return {
    type: "UPDATE_CURRENT_LESSON_ID",
    value,
  };
};

export const updateNodeState = (value) => {
  return {
    type: "UPDATE_NODE_STATE",
    value,
  };
};

export const toggleTutorialComplete = () => {
  return {
    type: "TOGGLE_TUTORIAL_COMPLETE",
  };
};

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_MATHJAX": {
      return {
        ...state,
        mathjax: action.value,
      };
    }
    case "TOGGLE_GRAPH": {
      return {
        ...state,
        showGraph: !state.showGraph,
      };
    }
    case "TOGGLE_LESSON_CHALLENGES": {
      return {
        ...state,
        showLessonChallenges: !state.showLessonChallenges,
      };
    }
    case "TOGGLE_LESSON_COMPLETE": {
      const { lessonId, isComplete } = action.value;
      const chapter = lessonId[0];

      return {
        ...state,
        graphs: state.graphs.map((graph) => {
          if (graph.title === chapter) {
            graph.nodes.map((node) => {
              if (node.id === lessonId) {
                node.isComplete = isComplete;
              }
              return node;
            });
          }
          return graph;
        }),
      };
    }
    case "TOGGLE_LESSON_TEXTBOOK": {
      return {
        ...state,
        showLessonTextbook: !state.showLessonTextbook,
      };
    }
    case "TOGGLE_TUTORIAL_COMPLETE": {
      return {
        ...state,
        tutorialComplete: true,
      };
    }
    case "TOGGLE_CHALLENGE_COMPLETE": {
      const { challengeIndex, lessonId, isComplete } = action.value;
      const chapter = lessonId[0];

      return {
        ...state,
        graphs: state.graphs.map((graph) => {
          if (graph.title === chapter) {
            graph.nodes.map((node) => {
              if (node.id === lessonId) {
                node.challenges.map((challenge, index) => {
                  if (index === challengeIndex) {
                    challenge.isComplete = isComplete;
                  }
                  return challenge;
                });
              }
              return node;
            });
          }
          return graph;
        }),
      };
    }
    case "TOGGLE_CHALLENGE_IS_CORRECT": {
      const { challengeIndex, lessonId, isCorrect } = action.value;
      const chapter = lessonId[0];
      return {
        ...state,
        graphs: state.graphs.map((graph) => {
          if (graph.title === chapter) {
            graph.nodes.map((node) => {
              if (node.id === lessonId) {
                node.challenges.map((challenge, index) => {
                  if (index === challengeIndex) {
                    challenge.isCorrect = isCorrect;
                  }
                  return challenge;
                });
              }
              return node;
            });
          }
          return graph;
        }),
      };
    }
    case "UPDATE_CHALLENGE_ANSWER": {
      const { challengeIndex, lessonId, answer } = action.value;
      const chapter = lessonId[0];

      return {
        ...state,
        graphs: state.graphs.map((graph) => {
          if (graph.title === chapter) {
            graph.nodes.map((node) => {
              if (node.id === lessonId) {
                node.challenges.map((challenge, index) => {
                  if (index === challengeIndex) {
                    challenge.answer = answer;
                  }
                  return challenge;
                });
              }
              return node;
            });
          }
          return graph;
        }),
      };
    }
    case "UPDATE_CHALLENGE_QUESTION_TEMPLATE": {
      const { challengeIndex, lessonId, questionTemplate } = action.value;
      const chapter = lessonId[0];

      return {
        ...state,
        graphs: state.graphs.map((graph) => {
          if (graph.title === chapter) {
            graph.nodes.map((node) => {
              if (node.id === lessonId) {
                node.challenges.map((challenge, index) => {
                  if (index === challengeIndex) {
                    challenge.questionTemplate = questionTemplate;
                  }
                  return challenge;
                });
              }
              return node;
            });
          }
          return graph;
        }),
      };
    }
    case "UPDATE_CHALLENGE_PLOTTING_DATA": {
      const { challengeIndex, lessonId, plottingData } = action.value;
      const chapter = lessonId[0];

      return {
        ...state,
        graphs: state.graphs.map((graph) => {
          if (graph.title === chapter) {
            graph.nodes.map((node) => {
              if (node.id === lessonId) {
                node.challenges.map((challenge, index) => {
                  if (index === challengeIndex) {
                    challenge.plottingData = plottingData;
                  }
                  return challenge;
                });
              }
              return node;
            });
          }
          return graph;
        }),
      };
    }
    case "UPDATE_CHALLENGE_MESSAGE": {
      const { challengeIndex, lessonId, challengeMessage } = action.value;
      const chapter = lessonId[0];

      return {
        ...state,
        graphs: state.graphs.map((graph) => {
          if (graph.title === chapter) {
            graph.nodes.map((node) => {
              if (node.id === lessonId) {
                node.challenges.map((challenge, index) => {
                  if (index === challengeIndex) {
                    challenge.challengeMessage = challengeMessage;
                  }
                  return challenge;
                });
              }
              return node;
            });
          }
          return graph;
        }),
      };
    }
    case "UPDATE_USER_OUTPUT": {
      const { challengeIndex, lessonId, userOutput } = action.value;
      const chapter = lessonId[0];

      return {
        ...state,
        graphs: state.graphs.map((graph) => {
          if (graph.title === chapter) {
            graph.nodes.map((node) => {
              if (node.id === lessonId) {
                node.challenges.map((challenge, index) => {
                  if (index === challengeIndex) {
                    challenge.userOutput = userOutput;
                  }
                  return challenge;
                });
              }
              return node;
            });
          }
          return graph;
        }),
      };
    }
    case "UPDATE_CURRENT_LESSON_ID": {
      const currentLessonId = action.value;

      return {
        ...state,
        currentLessonId,
      };
    }
    case "UPDATE_NODE_STATE": {
      const { title, nodeId, nodeState } = action.value;

      return {
        ...state,
        graphs: state.graphs.map((graph) => {
          if (graph.title === title) {
            graph.nodes.map((node) => {
              if (node.id === nodeId) {
                node.color = NODE_STATES[nodeState].color;
                node.chosen = NODE_STATES[nodeState].chosen;
                node.font = NODE_STATES[nodeState].font;
              }
              return node;
            });
          }
          return graph;
        }),
      };
    }
    default:
      return state;
  }
};
