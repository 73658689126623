import { NODE_STATES } from '../reducer'

const sGraph = {
  title: 's',
  nodes: [
    {
      id: 's.1',
      label: 'S.1',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Modular arithmetic',
      textbookFilename: 'lessons/S.1/Lesson.md',
      isComplete: false,
      nextLessonId: 's.2',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.1.1/challengeDescription.md',
          id: 'S.1.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.1.2/challengeDescription.md',
          id: 'S.1.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 's.2',
      label: 'S.2',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Classical factorization',
      textbookFilename: 'lessons/S.2/Lesson.md',
      isComplete: false,
      nextLessonId: 's.3',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.2.1/challengeDescription.md',
          id: 'S.2.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.2.2/challengeDescription.md',
          id: 'S.2.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.2.3/challengeDescription.md',
          id: 'S.2.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 's.3',
      label: 'S.3',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Period finding',
      textbookFilename: 'lessons/S.3/Lesson.md',
      isComplete: false,
      nextLessonId: 's.4',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.3.1/challengeDescription.md',
          id: 'S.3.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.3.2/challengeDescription.md',
          id: 'S.3.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.3.3/challengeDescription.md',
          id: 'S.3.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
      ]
    },
    {
      id: 's.4',
      label: 'S.4',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Shor\'s algorithm',
      textbookFilename: 'lessons/S.4/Lesson.md',
      isComplete: false,
      nextLessonId: 's.5',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.4.1/challengeDescription.md',
          id: 'S.4.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.4.2/challengeDescription.md',
          id: 'S.4.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''

        }
      ]
    },
    {
      id: 's.5',
      label: 'S.5',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'The RSA system',
      textbookFilename: 'lessons/S.5/Lesson.md',
      isComplete: false,
      nextLessonId: null,
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.5.1/challengeDescription.md',
          id: 'S.5.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/S.5.2/challengeDescription.md',
          id: 'S.5.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: ''
        }
      ]
    }
  ],
  edges: [
    { from: 's.1', to: 's.2' },
    { from: 's.2', to: 's.3' },
    { from: 's.3', to: 's.4' },
    { from: 's.4', to: 's.5' }
  ]
}

export default sGraph
