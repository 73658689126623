import { NODE_STATES } from '../reducer'

const aGraph = {
  title: 'a',
  nodes: [
    {
      id: 'a.1',
      label: 'A.1',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      title: 'No exponential magic',
      textbookFilename: 'lessons/A.1/Lesson.md',
      isComplete: false,
      nextLessonId: 'a.2',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.1.1/challengeDescription.md',
          id: 'A.1.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.1.2/challengeDescription.md',
          id: 'A.1.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'a.2',
      label: 'A.2',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'The magic 8-ball',
      textbookFilename: 'lessons/A.2/Lesson.md',
      isComplete: false,
      nextLessonId: 'a.3',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.2.1/challengeDescription.md',
          id: 'A.2.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.2.2/challengeDescription.md',
          id: 'A.2.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'a.3',
      label: 'A.3',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Pair programming',
      textbookFilename: 'lessons/A.3/Lesson.md',
      isComplete: false,
      nextLessonId: 'a.4',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.3.1/challengeDescription.md',
          id: 'A.3.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.3.2/challengeDescription.md',
          id: 'A.3.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
      ]
    },
    {
      id: 'a.4',
      label: 'A.4',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Making and breaking promises',
      textbookFilename: 'lessons/A.4/Lesson.md',
      isComplete: false,
      nextLessonId: 'a.5',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.4.1/challengeDescription.md',
          id: 'A.4.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.4.2/challengeDescription.md',
          id: 'A.4.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.4.3/challengeDescription.md',
          id: 'A.4.3',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'a.5',
      label: 'A.5',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Hadamard transform',
      textbookFilename: 'lessons/A.5/Lesson.md',
      isComplete: false,
      nextLessonId: 'a.6',
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.5.1/challengeDescription.md',
          id: 'A.5.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.5.2/challengeDescription.md',
          id: 'A.5.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        }
      ]
    },
    {
      id: 'a.6',
      label: 'A.6',
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font,
      title: 'Deutsch-Jozsa',
      textbookFilename: 'lessons/A.6/Lesson.md',
      isComplete: false,
      nextLessonId: null,
      nextChapterIds: null,
      challenges: [
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.6.1/challengeDescription.md',
          id: 'A.6.1',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
        {
          isComplete: false,
          isCorrect: null,
          descriptionFilename: 'lessons/A.6.2/challengeDescription.md',
          id: 'A.6.2',
          answer: '',
          questionTemplate: '',
          plottingData: null,
          challengeMessage: '',
          userOutput: ''
        },
      ]
    }
  ],
  edges: [
    { from: 'a.1', to: 'a.2' },
    { from: 'a.2', to: 'a.3' },
    { from: 'a.3', to: 'a.4' },
    { from: 'a.4', to: 'a.5' },
    { from: 'a.5', to: 'a.6' }
  ]
}

export default aGraph
