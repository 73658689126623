import graphs from './graphs'

const state = {
  showGraph: true,
  showLessonChallenges: true,
  showLessonTextbook: true,
  tutorialComplete: false,
  currentLessonId: null,
  mathjax: null,
  graphs
}

export default state
