import { NODE_STATES } from '../reducer'

const chapters = {
  title: 'chapters',
  nodes: [
    {
      id: 't',
      label: 'T',
      title: 'Tutorial',
      level: 0,
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font
    },
    {
      id: 'i',
      label: 'I',
      title: 'Intro to quantum computing',
      level: 1,
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font
    },
    {
      id: 'a',
      label: 'A',
      title: 'Basic quantum algorithms',
      level: 2,
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font
    },
    {
      id: 'f',
      label: 'F',
      title: "Quantum Fourier transform",
      level: 3,
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font
    },
    {
      id: 'p',
      label: 'P',
      title: "Quantum phase estimation",
      level: 4,
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font
    },      
     {
      id: 'g',
      label: 'G',
      title: "Grover's algorithm",
      level: 5,
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font
    },      
    {
      id: 'h',
      label: 'H',
      title: 'Hamiltonian simulation',
      level: 5,
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font
    },
    {
      id: 's',
      label: 'S',
      title: "Shor's algorithm",
      level: 5,
      color: NODE_STATES['enabled'].color,
      chosen: NODE_STATES['enabled'].chosen,
      font: NODE_STATES['enabled'].font
    },
  ],
  edges: [
    { from: 't', to: 'i' },
    { from: 'i', to: 'a' },
    { from: 'a', to: 'g' },
    { from: 'a', to: 'h' },
    { from: 'a', to: 'f' },
    { from: 'a', to: 'q' },
    { from: 'f', to: 'p' },
    { from: 'p', to: 'h' },
    { from: 'p', to: 's' },
  ]
}

export default chapters
